import React from 'react';
import { Helmet } from 'react-helmet';

import NavigationBar from '../NavigationBar';
import IEWarning from '../IEWarning';
import { createPreviewPath } from '../../helper';

function onloadCallback() {

  if (typeof document !== 'undefined') {

    const captchaEle = document.getElementsByClassName('grecaptcha-badge');
    if (captchaEle.length > 0) captchaEle[0].style.visibility = 'visible';

  }

}

class NavHead extends React.Component {

  /* eslint-disable default-case */
  static getFontSize(style, size) {

    if (style === 'h1') {

      switch (size) {

        case 'Small':
          return '3.4rem';
        case 'Normal':
          return '4.0rem';
        case 'Large':
          return '5.0rem';

      }

    }

    if (style === 'h2') {

      switch (size) {

        case 'Small':
          return '2.55rem';
        case 'Normal':
          return '3.0rem';
        case 'Large':
          return '3.75rem';

      }

    }

    if (style === 'h3') {

      switch (size) {

        case 'Small':
          return '1.7rem';
        case 'Normal':
          return '2.0rem';
        case 'Large':
          return '2.5rem';

      }

    }

    if (style === 'h4') {

      switch (size) {

        case 'Small':
          return '1.275rem';
        case 'Normal':
          return '1.5rem';
        case 'Large':
          return '1.875rem';

      }

    }

    if (style === 'h5') {

      switch (size) {

        case 'Small':
          return '1.19rem';
        case 'Normal':
          return '1.4rem';
        case 'Large':
          return '1.75rem';

      }

    }

    if (style === 'p') {

      switch (size) {

        case 'Small':
          return '0.85rem';
        case 'Normal':
          return '1.0rem';
        case 'Large':
          return '1.25rem';

      }

    }

    if (style === 'button') {

      switch (size) {

        case 'Small':
          return '0.85rem';
        case 'Normal':
          return '1.0rem';
        case 'Large':
          return '1.25rem';

      }

    }

  }
  /* eslint-enable default-case */

  constructor(props) {

    super(props);

    const head = [];
    const before = [];
    const beforeSrc = [];
    if (typeof document !== 'undefined') {

      const parser = new DOMParser();

      this.props.pageContext.seo.trackers.forEach(tracker => {

        if (tracker.active === true) {

          if (
            tracker.visibility.visible === 'sitewide'
            || (
              tracker.visibility.visible === 'all'
              && !tracker.visibility.pages.includes(this.props.pageContext.page._id.valueOf())
            )
            || (
              tracker.visibility.visible === 'only'
              && tracker.visibility.pages.includes(this.props.pageContext.page._id.valueOf())
            )
          ) {

            const doc = parser.parseFromString(tracker.snippet, 'text/html');
            const scripts = doc.getElementsByTagName('script');

            Array.prototype.forEach.call(scripts, (script, index) => {

              const attributes = {};
              const element = document.createElement('script');
              element.innerHTML = script.innerHTML;

              if (script.attributes.length > 0) {

                Array.prototype.forEach.call(script.attributes, attribute => {

                  attributes[attribute.nodeName] = attribute.nodeValue;

                });

              }

              const newElement = <script key={`script_${index}`} {...attributes}>{`${script.innerHTML}`}</script>;

              if (tracker.placement === 'head') {

                head.push(newElement);

              } else if (tracker.placement === 'before') {

                before.push(script.innerHTML);

                if (attributes.src) {

                  beforeSrc.push({ ...attributes });

                }

              }

            });

          }

        }

      });

    }

    const nos = [];
    props.pageContext.seo.trackers.forEach(tracker => {

      if (tracker.active === true && tracker.snippet.includes('<noscript>')) {

        const sIndex = tracker.snippet.indexOf('<noscript>');
        const eIndex = tracker.snippet.indexOf('</noscript>');
        let noscript = tracker.snippet.substring((sIndex + '<noscript>'.length), eIndex);
        noscript = noscript.replace(/“/g, '"');
        noscript = noscript.replace(/”/g, '"');
        noscript = noscript.replace(/\r\n/g, ' ');
        noscript = noscript.replace(/\r/g, ' ');
        noscript = noscript.replace(/\n/g, ' ');
        noscript = noscript.replace(/\\/g, '');
        nos.push(noscript);

      }

    });

    const noscripts = [];
    if (nos.length > 0) {

      nos.forEach(no => {

        noscripts.push(<noscript>{`${no}`}</noscript>);

      });

    }

    let previewImage;
    if (props.previewSrc) previewImage = props.previewSrc;
    else if (
      props.pageContext.page.previewImage !== null
      && props.pageContext.page.previewImage !== undefined
      && props.pageContext.page.previewImage !== ''
    ) {

      previewImage = props.pageContext.page.previewImage;

    } else if (
      props.pageContext.seo.defaultPreview !== undefined
      && props.pageContext.seo.defaultPreview !== null
      && props.pageContext.seo.defaultPreview !== ''
    ) {

      previewImage = props.pageContext.seo.defaultPreview;

    }

    if (!props.previewSrc && previewImage !== undefined) {

      props.pageContext.images.some(image => {

        let found = false;
        if (image.path === previewImage) {

          found = true;
          previewImage = createPreviewPath(image);

        }

        return found;

      });

    }

    const siteTitle = props.pageContext.seo.siteName
      ? props.pageContext.seo.siteName
      : props.pageContext.sitename;
    const pageTitle = props.pageContext.page.metaTitle
      ? props.pageContext.page.metaTitle : props.pageContext.page.title;

    let title = '';
    if (props.metaTitle) title = `${props.metaTitle} | ${siteTitle}`;
    else if (props.title) title = `${props.title} | ${siteTitle}`;
    else if (siteTitle && pageTitle) title = `${pageTitle} | ${siteTitle}`;
    else if (siteTitle) title = siteTitle;
    else title = pageTitle;

    let description;
    if (props.metaDescription) description = props.metaDescription;
    else if (props.desc) description = props.desc;
    else if (
      props.pageContext.page
      && props.pageContext.page.metaDescription
    ) description = props.pageContext.page.metaDescription;

    this.state = {
      noscripts,
      head,
      before,
      beforeSrc,
      title,
      previewImage,
      description,
    };

  }

  componentDidMount() {

    if (typeof window !== 'undefined') {

      const fonts = new Set();

      Object.keys(this.props.pageContext.themeData.typography).forEach(key => {

        const font = this.props.pageContext.themeData.typography[key];
        fonts.add(`${font.name}:${key === 'logo' ? '400,' : ''}${font.weight}`);

      });

      if (fonts.size > 0) {

        // eslint-disable-next-line global-require
        const WebFont = require('webfontloader');

        WebFont.load({
          google: {
            families: Array.from(fonts),
          },
        });

      }

    }

    this.state.beforeSrc.forEach(src => {

      const script = document.createElement('script');
      Object.entries(src).forEach(([key, value]) => script[key] = value);
      document.body.appendChild(script);

    });

    this.state.before.forEach(beforeScript => {

      const script = document.createElement('script');
      script.innerHTML = beforeScript;
      document.body.appendChild(script);

    });

  }

  render() {

    const themeHeadingStyle = {
      fontFamily: this.props.pageContext.themeData.typography.heading.name,
      weight: this.props.pageContext.themeData.typography.heading.weight,
      lineHeight: this.props.pageContext.themeData.typography.heading.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.heading.letterSpacing,
      textTransform: this.props.pageContext.themeData.typography.heading.textTransform,
    };

    const themeDefaultStyle = {
      fontFamily: this.props.pageContext.themeData.typography.default.name,
      weight: this.props.pageContext.themeData.typography.default.weight,
      lineHeight: this.props.pageContext.themeData.typography.default.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.default.letterSpacing,
    };

    const themeNavigationStyle = {
      fontFamily: this.props.pageContext.themeData.typography.navigation.name,
      weight: this.props.pageContext.themeData.typography.navigation.weight,
      lineHeight: this.props.pageContext.themeData.typography.navigation.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.navigation.letterSpacing,
      textTransform: this.props.pageContext.themeData.typography.navigation.textTransform,
    };

    const themeButtonStyle = {
      fontFamily: this.props.pageContext.themeData.typography.button.name,
      weight: this.props.pageContext.themeData.typography.button.weight,
      lineHeight: this.props.pageContext.themeData.typography.button.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.button.letterSpacing,
      textTransform: this.props.pageContext.themeData.typography.button.textTransform,
    };

    const themeFooterStyle = {
      fontFamily: this.props.pageContext.themeData.typography.footer.name,
      weight: this.props.pageContext.themeData.typography.footer.weight,
      lineHeight: this.props.pageContext.themeData.typography.footer.lineHeight,
      letterSpacing: this.props.pageContext.themeData.typography.footer.letterSpacing,
    };

    const color0 = {
      color: this.props.pageContext.themeData.colors[0],
    };

    const color1 = {
      color: this.props.pageContext.themeData.colors[1],
    };

    const color3 = {
      color: this.props.pageContext.themeData.colors[3],
    };

    const css = `
      html { scroll-behavior: smooth; }
      body { margin: 0; font-weight: ${themeDefaultStyle.weight}; font-family: ${themeDefaultStyle.fontFamily}, Source Sans Pro, sans-serif; }
      h1  { margin: 0; font-size:${NavHead.getFontSize('h1', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h2 { margin: 0; font-size:${NavHead.getFontSize('h2', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h3 { margin: 0; font-size:${NavHead.getFontSize('h3', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h4 { margin: 0; font-size:${NavHead.getFontSize('h4', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h5 { margin: 0; font-size:${NavHead.getFontSize('h5', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h1 span { font-size:${NavHead.getFontSize('h1', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h2 span { font-size:${NavHead.getFontSize('h2', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h3 span { font-size:${NavHead.getFontSize('h3', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h4 span { font-size:${NavHead.getFontSize('h4', this.props.pageContext.themeData.typography.heading.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.heading.textTransform}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${themeHeadingStyle.weight}; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h3.footerHeading { font-size:${NavHead.getFontSize('h3', this.props.pageContext.themeData.typography.footer.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.footer.textTransform}; font-family: ${themeFooterStyle.fontFamily}; font-weight: ${themeFooterStyle.weight}; line-height: ${themeFooterStyle.lineHeight}; letter-spacing: ${themeFooterStyle.letterSpacing}; }
      .storyFull h1, .storyFull h2, .storyFull h3, .storyFull h4, .storyFull span { color: ${color1.color}; }
      span { font-size:${NavHead.getFontSize('p', themeDefaultStyle.fontSize)}; font-family: ${themeDefaultStyle.fontFamily}; font-weight: ${themeDefaultStyle.weight}; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${themeDefaultStyle.letterSpacing}; }
      .NavLink a, .NavLink ul a li {font-size:${NavHead.getFontSize('p', this.props.pageContext.themeData.typography.navigation.fontSize)}; color: ${color0.color}; font-family: ${themeNavigationStyle.fontFamily}; text-transform:${this.props.pageContext.themeData.typography.navigation.textTransform}; font-weight: ${themeNavigationStyle.weight}; line-height: ${themeNavigationStyle.lineHeight}; letter-spacing: ${themeNavigationStyle.letterSpacing}; }
      a { color: ${color0.color}; font-family: ${themeNavigationStyle.fontFamily}; text-transform:${this.props.pageContext.themeData.typography.navigation.textTransform}; font-weight: ${themeNavigationStyle.weight}; line-height: ${themeNavigationStyle.lineHeight}; letter-spacing: ${themeNavigationStyle.letterSpacing}; }
      button { font-size:${NavHead.getFontSize('button', this.props.pageContext.themeData.typography.button.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.button.textTransform}; font-family: ${themeButtonStyle.fontFamily}; font-weight: ${themeButtonStyle.weight}; line-height: ${themeButtonStyle.lineHeight}; letter-spacing: ${themeButtonStyle.letterSpacing}; }
      p, .infoRow {font-size:${NavHead.getFontSize('p', this.props.pageContext.themeData.typography.default.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.default.textTransform}; font-family: ${themeDefaultStyle.fontFamily}; font-weight: ${themeDefaultStyle.weight}; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${themeDefaultStyle.letterSpacing};  margin-block-start: unset; margin-block-end: unset; }
      p a {color: ${color0.color}; font-size:${NavHead.getFontSize('p', this.props.pageContext.themeData.typography.default.fontSize)}; text-transform:${this.props.pageContext.themeData.typography.default.textTransform}; font-family: ${themeDefaultStyle.fontFamily}; font-weight: ${themeDefaultStyle.weight}; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${themeDefaultStyle.letterSpacing}; margin-block-start: unset; margin-block-end: unset; }
      .inputLabel p, .inputLabel p span, .inputLabel span { font-size: ${NavHead.getFontSize('p', this.props.pageContext.themeData.typography.default.fontSize)}; color: ${color3.color}; font-family: ${themeDefaultStyle.fontFamily}; font-weight: ${themeDefaultStyle.weight}; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${themeDefaultStyle.letterSpacing}; }
      .grecaptcha-badge { z-index: 50; }
    `;

    return (
      <React.Fragment>
        <Helmet>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=yes" />
          <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
          <html lang={this.props.pageContext.language} />
          { this.state.head }
          <title>{this.state.title}</title>
          {
            this.state.description
            && (<meta name="description" content={this.state.description} />)
          }
          {
            this.state.description
            && (<meta property="og:description" content={this.state.description} />)
          }
          {
            this.props.pageContext.page.metaTitle
            && (<meta property="og:title" content={this.props.pageContext.page.metaTitle} />)
          }
          {
            this.state.previewImage
            && (<meta property="og:image" content={this.state.previewImage} />)
          }
          {
            this.state.noscripts.length > 0
            && (this.state.noscripts)
          }
          {
            this.props.pageContext.hasArticles
            && (
              <link
                rel="alternate" type="application/rss+xml"
                title={this.state.title}
                href="/rss.xml"
              />
            )
          }
          {
            this.props.pageContext.hasContact
            && (
              <script src={`https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=${process.env.RECAPTCHA_SITE_KEY}`} async defer />
            )
          }
        </Helmet>
        <style>{css}</style>
        <IEWarning />
        <NavigationBar isScrolling={this.props.isScrolling} pageContext={this.props.pageContext} />
      </React.Fragment>
    );

  }

}

export default NavHead;

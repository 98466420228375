import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import Logo from '../../../../images/oidom-logo.svg';
import styles from './styles.module.css';

class FooterLayout extends React.Component {

  constructor(props) {

    const full = props.section.styles.full === true ? 'Full' : '';

    super(props);
    this.state = {
      section: props.section,
      layout: props.layout,
      full,
    };

  }

  render() {

    const activeComponents = [];
    const components = [];
    this.state.section.data.map((item, index) => {

      if (item.type.startsWith('COMPONENT') && item.active) {

        activeComponents.push({ item, index });

      }

      return null;

    });

    activeComponents.map((component, componentIndex) => {

      let bg;
      if (component.item && component.item.styles && component.item.styles.backgroundColor.active) {

        let { backgroundColor } = component.item.styles;
        if (
          component.item.styles.backgroundColor.solid === ''
          && component.item.styles.backgroundColor.gradient.from === ''
        ) {

          backgroundColor = { solid: '#ffffff' };

        }

        bg = formColor(
          backgroundColor,
          false,
          component.item.styles.backgroundColor.opacity,
        );

      }

      components.push({
        label: component.item.label,
        index: component.index,
        elements: [],
        style: bg,
        type: component.item.type,
      });

      component.item.data.map((element, elementIndex) => {

        let item;
        let type;
        const align = styles[`align${component.item.type === 'COMPONENT/PERMANENT' && componentIndex ? 'Disc' : ''}${component.item.align}`];

        if (element.active && element.type === 'IMAGES/IMAGE') {

          item = (
            <SectionMedia
              key={componentIndex === 0 ? `${this.state.section._id}_layout_${elementIndex}_upper` : ''}
              mediaType={(this.state.section.data[component.index].data[elementIndex].content.icon) ? 'ICON' : 'IMAGE'}
              wrapperStyle="footerImage"
              elementStyle="footerImage"
              iconStyle=""
              sizes="16vw"
              align={component.item.align}
              src={this.state.section.data[component.index].data[elementIndex].content.src}
              alt={this.state.section.data[component.index].data[elementIndex].content.alt}
              data={this.state.section.data[component.index].data[elementIndex].content}
              images={this.props.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
            />);

        } else if (element.active && element.type === 'PARAGRAPH/PARAGRAPH') {

          type = 'paragraph';
          item = (<span>{HTMLParser(element.text)}</span>);

        } else if (element.active && element.type === 'HEADINGS/HEADING-THREE') {

          type = 'header';
          item = (<h3 className="footerHeading">{HTMLParser(element.text)}</h3>);

        }

        if (item) {

          if (element.type === 'IMAGES/IMAGE') {

            components[componentIndex].elements.push(item);

          } else {

            const editor = (
              <div
                key={componentIndex === 0 ? `${this.state.section._id}_layout_${elementIndex}_upper` : ''}
                className={`${type === 'header' ? styles.footerHeader : ''} ${styles.footerTextWrapper} ${align}`}
              >
                {item}
              </div>
            );

            components[componentIndex].elements.push(editor);

          }

        }

        return null;

      });

      return null;

    });

    const { active } = this.state.section.data[0];
    const left = [];
    const right = [];
    const disclaimer = [];

    components.map(component => {

      if (active && component.index === 0) {

        left.push(component);

      } else if (component.index > 0 && component.type === 'COMPONENT') {

        right.push(component);

      } else if (component.index > 0 && component.type === 'COMPONENT/PERMANENT') {

        disclaimer.push(component);

      }

      return null;

    });

    let className;
    if (right.length === 1) {

      className = `${styles.colWrapper} col-12`;

    } else if (right.length === 2) {

      className = `${styles.colWrapper} col-12${this.state.layout === 'firstWide' && right[0].index === 1 ? '' : ' col-sm-6'}`;

    } else if (right.length === 3) {

      className = `${styles.colWrapper} col-12 col-sm-6${this.state.layout === 'firstWide' && right[0].index === 1 ? '' : ' col-md-4'}`;

    } else {

      className = `${styles.colWrapper} col-12 col-sm-6 col-md-4${this.state.layout === 'firstWide' ? '' : ' col-lg-3'}`;

    }

    let line;
    if (this.state.layout === 'firstWide') {

      line = <div className={`col-12 ${styles.line}`} />;

    }

    let disc;
    if (disclaimer.length > 0 || this.props.badge) {

      const texts = [];
      if (disclaimer.length > 0) {

        disclaimer.forEach(component => {

          const text = (
            <div
              className={`col-12${disclaimer.length > 1 ? ' col-sm-6' : ''}`}
              key={`${this.state.section._id}_Component_${component.index}_section`}
              style={component.style}
            >
              {
                component.elements.map((element, index) => (
                  <div
                    key={`${this.state.section._id}_layout_${index}_lower`}
                    className={index === 0
                      ? styles.footerDisclaimerRight
                      : styles.footerDisclaimerLeft
                    }
                  >
                    { element }
                  </div>
                ))
              }
            </div>
          );
          texts.push(text);

        });

      }

      let badge;
      if (this.props.badge && this.props.badge.active) {

        badge = (
          <a href={`https://oidom.${this.props.siteLanguage === 'fi' ? 'fi' : 'com'}`} target="_blank" rel="noopener noreferrer">
            <div className={`${styles.badgeContainer}${disclaimer.length === 0 ? ` ${styles.padding}` : ''}`}>
              <span
                style={{
                  color: this.props.badge.colour === 'dark' ? '#000' : '#fff',
                }}
              >
                <p>{this.props.badge.text}</p>
              </span>
              <img src={Logo} alt="oidom badge" className={styles.badge} />
            </div>
          </a>
        );

      }

      let content;
      if (
        (disclaimer.length) > 0
        && (!this.props.badge || (this.props.badge && !this.props.badge.active))
      ) {

        content = texts;

      } else if (disclaimer.length === 0 && this.props.badge && this.props.badge.active) {

        content = badge;

      } else {


        content = (
          <div className={`row ${styles.badgeWrapper}`}>
            <div className={`col-12 col-sm-9 ${styles.badgeDisclaimer}`}>
              { texts }
            </div>
            <div className="col-12 col-sm-3">
              { badge }
            </div>
          </div>
        );

      }

      disc = (
        <div className={`row ${styles.footerDisclaimerWrapper}${disclaimer.length === 0 ? ` ${styles.end}` : ''}`}>
          { line }
          { content }
        </div>
      );

    }

    return (
      <div className={`container ${styles[`footerContainer${this.state.full}`]}`}>
        <div className={`row ${styles[this.state.layout]}`}>
          {
            active
            && (
              <div className="col-12 col-md-3">
                <div
                  className={`${styles.social} ${styles[`align${this.state.section.data[0].align}`]}`}
                  style={left[0].style}
                >
                  { left[0].elements }
                </div>
              </div>
            )
          }
          {
            <div className={!active ? 'col-12' : 'col-12 col-md-9'}>
              <div className="row">
                {
                  right.map((component, index) => (
                    <div
                      key={`${this.state.section._id}_layout_${index}`}
                      className={`${this.state.layout === 'firstWide' && component.index === 1 ? 'col-12' : className}${this.state.layout === 'firstWide' && component.index === 1 ? ` ${styles.wide}` : ''}`}
                      style={component.style}
                    >
                      {
                        component.elements.map((element, index2) => (
                          <React.Fragment key={`${this.state.section._id}_layout_${index}_${index2}`}>
                            { element }
                          </React.Fragment>
                        ))
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </div>
        { disc }
      </div>
    );

  }

}

FooterLayout.propTypes = {
  layout: PropTypes.string,
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
      full: PropTypes.bool,
    })),
  }),
  siteLanguage: PropTypes.string,
  badge: PropTypes.shape({
    text: PropTypes.string,
    colour: PropTypes.string,
  }),
};

export default CSSModules(FooterLayout, styles, { allowMultiple: true });

import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.css';

import helper from './helper';
import * as crypto from 'crypto';

import brokenImage from '../../images/broken.svg';

class Image extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      imageId: crypto.randomBytes(12).toString('hex'),
    };

  }

  preloadImage(element) {

    if (element.dataset && element.dataset.imgsrc) {

      element.src = element.dataset.imgsrc;

    }

    if (element.dataset && element.dataset.srcset) {

      element.srcset = element.dataset.srcset;

    }

    if (element.className) {

      // element.className = ` ${styles.noBlurImage} ${styles[this.props.imageClass]}`;
      element.className = styles[this.props.imageClass];

    }

  }

  constructImage(imageId) {

    let image;

    if (imageId && imageId.length > 0) {

      image = helper.getImageById(imageId, this.props.images);

    }

    if (!image) {

      return null;

    }

    return image;

  }

  componentDidMount() {

    if (false && typeof IntersectionObserver !== 'undefined') { // TODO disabled

      const config = {
        rootMargin: '50px 0px',
        threshold: 0,
      };

      const thisComponent = this;
      const imageObserver = new IntersectionObserver(function (entries, self) {

        entries.forEach(entry => {

          let intersected = false;

          if (entry.isIntersecting) {

            intersected = true;
            thisComponent.preloadImage(entry.target);

          }

          if (intersected) {

            // using disconnect to destroy the intersectionObserver, which is only for one image
            self.disconnect();

          }

        });

      }, config);

      const img = document.getElementById(this.state.imageId);

      if (img) {

        imageObserver.observe(img);

      }

    }

  }

  render() {

    let image = this.constructImage(this.props.id);

    if (!image && !this.props.id && this.props.url) {

      image = {
        path: this.props.url,
        alt: this.props.alt || 'static',
      };

    }

    const style = {}; // to avoid empty props in the html

    if (this.props.position) {

      style.objectPosition = this.props.position;

    }

    if (this.props.opacity) {

      style.opacity = this.props.opacity;

    }

    if (this.props.size) {

      style.objectFit = this.props.size;

    }

    const srcset = helper.getSrcSet(image);

    let frmt = 'sm';

    if (image && image.sizes && image.sizes.includes('og')) {

      frmt = 'og';

    } else {

      frmt = (image && image.sizes && image.sizes.includes('me')) ? 'me' : 'sm';

      if (image && image.sizes && !image.sizes.includes(frmt) && image.sizes.length > 0) {

        frmt = image.sizes[0];

      }

      // Check if browser supports src and sizes W
      if (typeof document !== 'undefined' && document) {

        const img = document.createElement('img');

        if ('sizes' in img) {

          frmt = (image && image.sizes && image.sizes.includes('th')) ? 'th' : 'sm';
          // sizes and srcset is suppored so use the lowest quality here for blur

        }

      }

    }

    let path = null;
    if (image && image.path) path = image.path;

    const imgsrc = helper.getImgSrc(path, frmt);
    const lastSlash = imgsrc.lastIndexOf('/');

    // let className = styles.blurImage;  // TODO disabled

    let className = '';

    if (this.props.imageClass && this.props.imageClass.length > 0) {

      // className = `${styles.blurImage} ${styles[this.props.imageClass]}`; // TODO disabled
      className = styles[this.props.imageClass];

    }


    // random alt if not set for better SEO. Actual alt text would be much better though.
    const alt = this.props.alt
      || ((image && image.alt) ? image.alt : imgsrc.substring(lastSlash + 1));

    if (srcset && srcset.length > 0) {

      return (
        <img
          loading="eager"
          src={imgsrc}
          data-imgsrc={imgsrc}
          srcset={srcset}
          sizes={this.props.sizes || '100vw'}
          alt={alt}
          data-index={this.props.dataIndex}
          className={className}
          onClick={this.props.onClick}
          style={style}
          data-imageid={this.props.id}
          id={this.state.imageId}
        />
      );

    }

    return (
        <img
          src={imgsrc && imgsrc.length > 1 ? imgsrc : brokenImage}
          data-imgsrc={imgsrc}
          alt={alt}
          data-index={this.props.dataIndex}
          className={className}
          onClick={this.props.onClick}
          style={style}
          data-imageid={this.props.id}
          id={this.state.imageId}
        />
    );

  }

}

Image.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  sizes: PropTypes.string,
  imageClass: PropTypes.string,
  imageStyle: PropTypes.string,
  dataIndex: PropTypes.number,
  position: PropTypes.string,
  opacity: PropTypes.number,
  url: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
};

export default CSSModules(Image, styles, { allowMultiple: true });
